import { MediaQuery, mq } from '@/ui/styles/mediaQueries';
import { css } from '@emotion/css';

export const timerWrapper = css({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  alignItems: 'center',
  justifyContent: 'center',
});

export const timerBox = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'var(--Light-2, #fafaf9)',
  padding: '1rem 2rem',
  [mq(MediaQuery.Mobile)]: {
    padding: '0.5rem 1rem',
  },
});

export const number = css({
  color: 'var(--Gray-6, #1e293b)',
  textAlign: 'center',
  fontFamily: "'Space Grotesk', sans-serif",
  fontSize: '32px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '110%',
  letterSpacing: '-0.96px',
  [mq(MediaQuery.Mobile)]: {
    fontSize: '16px',
    letterSpacing: '-0.5px',
  },
});

export const name = css({
  color: 'var(--Gray-6, #1e293b)',
  textAlign: 'center',
  fontFamily: "'Inter', sans-serif",
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '150%',
  [mq(MediaQuery.Mobile)]: {
    fontSize: '12px',
  },
});
