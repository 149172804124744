export const AppRoute = {
  home: '/',
  demo: '/lab/demo',
  board: '/board',
  portfolioEdit: '/p/edit',
  portfolio: '/p',
  group: '/g',
  programs: '/programs',
  login: '/login',
  signUp: '/sign-up',
  notFound: '/not-found',
  workshops: '/workshops',
  leaderboard: '/leaderboard',
  g: {
    gitWorkshop: '/g/git-workshop',
    reactWorkshop: '/g/react-intro-workshop',
    bunExperiment: '/g/bun-experiment',
    devMentor: '/g/warcaby-oop-z-devmentor',
    vitest: '/g/nerdbord-labs-vitest-rtl',
  },
  landing: {
    home: '/landing',
    workshops: '/workshops',
    leaderboard: '/ranking',
  },
  lab: {
    base: '/lab',
    dashboard: '/',
    onboarding: '/onboarding',
    library: '/library',
    group: '/groups/:cuid',
    billing: '/settings/billing',
    members: '/settings/members',
    settings: '/settings',
  },
  auth: {
    lab: '/lab-redirect',
    connect: '/connect-provider',
    githubOauth: '/github-oauth',
    logout: '/logout',
    refresh: '/refresh-token',
  },
  registration: {
    userRegistration: '/join',
    teamRegistration: '/join/team',
    confirmation: '/join/confirmation',
  },
};
