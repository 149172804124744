import { I18nNamespaces } from '@/utils/i18n.namespaces';
import { useFindPublicPrimaryGroup } from '@nerdbord/graphql-api';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import {
  calculateTimeRemaining,
  TimeRemaining,
} from './countdownTimer';
import * as styles from './Timer.styles';

export const Timer = () => {
  const { group } = useFindPublicPrimaryGroup();
  const { t } = useTranslation(I18nNamespaces.Homepagev2);

  const [timeRemaining, setTimeRemaining] = useState<TimeRemaining>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    if (group.settings?.startAt) {
      const startAtTimestamp = parseInt(group.settings.startAt, 10) + (17 * 60 * 60 * 1000);
      setTimeRemaining(calculateTimeRemaining(startAtTimestamp));

      const intervalId = setInterval(() => {
        setTimeRemaining(calculateTimeRemaining(startAtTimestamp));
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [group.settings?.startAt]);

  return (
    <div className={styles.timerWrapper}>
      <div className={styles.timerBox}>
        <span className={styles.number}>{timeRemaining.days}</span>
        <p className={styles.name}>{t('component.counter.days')}</p>
      </div>
      <div className={styles.timerBox}>
        <span className={styles.number}>{timeRemaining.hours}</span>
        <p className={styles.name}>{t('component.counter.hours')}</p>
      </div>
      <div className={styles.timerBox}>
        <span className={styles.number}>{timeRemaining.minutes}</span>
        <p className={styles.name}>
          {t('component.counter.minutes')}
        </p>
      </div>
      <div className={styles.timerBox}>
        <span className={styles.number}>{timeRemaining.seconds}</span>
        <p className={styles.name}>
          {t('component.counter.seconds')}
        </p>
      </div>
    </div>
  );
};
