import { AppRoute } from '@/config/routes';
import { Container } from '@/ui/components/Container/Container';
import { useMediaQuery } from '@/ui/hooks/useMediaQuery';
import ActionButton from '@/ui/landing-v2/components/ActionButton/ActionButton';
import { Timer } from '@/ui/landing-v2/components/Timer/Timer';
import { formatDateRangeFromTimestamps } from '@/utils/date';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import { useFindPublicPrimaryGroup } from '@nerdbord/graphql-api';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import styles from './EventSummary.module.scss';
export const EventSummary = () => {
  const router = useRouter();
  const { t, i18n } = useTranslation(I18nNamespaces.Homepagev2);
  const { isMobile, isDesktop } = useMediaQuery();
  const { group } = useFindPublicPrimaryGroup();
  const lang = i18n.language;

  const formattedDateRange =
    group.settings?.startAt && group.settings?.endAt
      ? formatDateRangeFromTimestamps(
          group.settings.startAt,
          group.settings.endAt,
          lang
        )
      : t('section.consumerism.start.comingSoon');

  return (
    <div id="hackathon" className={styles.eventSummary}>
      {isMobile && (
        <>
          <Container>
            <div className={styles.counterBox}>
              <div className={styles.timerTextWrapper}>
                <span className={styles.timerText}>
                  {t('section.consumerism.counter.timeText.mobile')}
                </span>
                <div className={styles.timerScaleWrapper}>
                  <Timer />
                </div>
              </div>
              <span className={styles.timerHeader}>
                <p> {group?.name || 'Coming soon'}</p>
              </span>
            </div>
            <div className={styles.descriptionBox}>
              <span className={styles.description}>
                {group?.page?.shortDsc ||
                  'Next hackathon is coming soon!'}
              </span>
            </div>
          </Container>
          <div className={styles.imageWrapper}>
            <div className={styles.imageMobile}></div>
          </div>
          <Container>
            <div className={styles.infoSection}>
              <div className={styles.infoBox}>
                <p className={styles.infoBoxTitle}>
                  {t('section.consumerism.start.title')}
                </p>
                <span className={styles.infoBoxContent}>
                  {formattedDateRange}
                </span>
              </div>

              <div className={styles.mobileWrapper}>
                <div className={styles.infoBoxMobile}>
                  <p className={styles.infoBoxTitle}>
                    {t('section.consumerism.teams.title')}
                  </p>
                  <span className={styles.infoBoxContent}>
                    1 – 4 {t('section.consumerism.teams.content')}
                  </span>
                </div>
                <div className={styles.infoBoxMobile}>
                  <p className={styles.infoBoxTitle}>
                    {t('section.consumerism.entryFee.title')}
                  </p>
                  <span className={styles.infoBoxContent}>
                    {group.pricePerSeat} {group.currency} /{' '}
                    {t('section.consumerism.entryFee.content')}
                  </span>
                </div>
              </div>
              <ActionButton
                onClick={() =>
                  router.push(AppRoute.registration.userRegistration)
                }
                variant="primary"
              >
                {t('section.consumerism.register.title')}
              </ActionButton>
              <div className={styles.teamMatchingInfo}>
                <p>TEAM MATCHING</p>
                <span>
                  {t('section.consumerism.matchTeam.title')}
                </span>
              </div>
            </div>
          </Container>
        </>
      )}
      {isDesktop && (
        <>
          <Container>
            <div className={styles.imageWrapper}>
              <div className={styles.image}></div>
            </div>
            <div className={styles.counterBox}>
              <div className={styles.timerTextWrapper}>
                <span className={styles.timerText}>
                  {t('section.consumerism.counter.timeText')}
                </span>
                <div className={styles.timerScaleWrapper}>
                  <Timer />
                </div>
              </div>
              <span className={styles.timerHeader}>
                {group?.name || 'Coming soon'}
              </span>
            </div>
            <div className={styles.descriptionBox}>
              <span className={styles.description}>
                {group?.page?.shortDsc ||
                  'Next hackathon is coming soon!'}
              </span>
            </div>

            <div className={styles.infoSection}>
              <div className={styles.infoBox}>
                <p className={styles.infoBoxTitle}>
                  {t('section.consumerism.start.title')}
                </p>
                <span className={styles.infoBoxContent}>
                  {formattedDateRange}
                </span>
              </div>
              <div className={styles.infoBox}>
                <p className={styles.infoBoxTitle}>
                  {t('section.consumerism.teams.title')}
                </p>
                <span className={styles.infoBoxContent}>
                  1 – 4 {t('section.consumerism.teams.content')}
                </span>
              </div>
              <div className={styles.infoBox}>
                <p className={styles.infoBoxTitle}>
                  {t('section.consumerism.entryFee.title')}
                </p>
                <span className={styles.infoBoxContent}>
                  {group.pricePerSeat} {group.currency} /{' '}
                  {t('section.consumerism.entryFee.content')}
                </span>
              </div>
              <ActionButton
                onClick={() =>
                  router.push(AppRoute.registration.userRegistration)
                }
                variant="primary"
              >
                {t('section.consumerism.register.title')}
              </ActionButton>
              <div className={styles.teamMatchingInfo}>
                <p>TEAM MATCHING</p>
                <span>
                  {t('section.consumerism.matchTeam.title')}
                </span>
              </div>
            </div>
          </Container>
        </>
      )}
    </div>
  );
};
