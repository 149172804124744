import { PageHead } from '@/components/PageHead/PageHead';
import { UserEntity } from '@nerdbord/graphql-api';
import { PropsWithChildren } from 'react';
import '../styles/globals.scss';
import { Counter } from './Counter/Counter';
import { FAQ } from './FAQ/FAQ';
import Footer from './Footer/Footer';
import Navbar from './Navbar/Navbar';

interface LayoutProps {
  pageTitle: string;
  metaDesc: string;
  ogImageSrc?: string | null;
  user: UserEntity | null;
}

export const Layout = (props: PropsWithChildren<LayoutProps>) => {
  return (
    <>
      <PageHead {...props} />
      <Navbar user={props.user} />
      <main>{props.children}</main>
      <FAQ />
      <Counter />
      <Footer />
    </>
  );
};
