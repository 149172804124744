import { AppRoute } from '@/config/routes';
import { Container } from '@/ui/components/Container/Container';
import { useMediaQuery } from '@/ui/hooks/useMediaQuery';
import { I18nNamespaces } from '@/utils/i18n.namespaces';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import ActionButton from '../../components/ActionButton/ActionButton';
import { Timer } from '../../components/Timer/Timer';
import styles from './Counter.module.scss';
export const Counter = () => {
  const { isMobile, isDesktop } = useMediaQuery();
  const { t } = useTranslation(I18nNamespaces.Homepagev2);
  const router = useRouter();
  const handleJoinUs = () => {
    router.push(AppRoute.signUp);
  };

  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.counterWrapper}>
          <h2 className={styles.title}>
            {isDesktop
              ? t('component.counter.header')
              : t('component.counter.header.mobile')}
          </h2>
          <h4 className={styles.subtitle}>
            {t('component.counter.description')}
          </h4>
          <Timer />
          <ActionButton onClick={handleJoinUs} variant="primary">
            {t('navbar.button.join')}
          </ActionButton>
        </div>
      </Container>
    </div>
  );
};
