import React from 'react';
import styles from './ActionButton.module.scss';

interface ActionButtonProps {
  children: React.ReactNode;
  onClick: () => void;
  variant: 'primary' | 'secondary' | 'tertiary';
}

const ActionButton = ({
  children,
  onClick,
  variant,
}: ActionButtonProps) => {
  const buttonClass = `${styles.button} ${styles[variant]}`;

  return (
    <button className={buttonClass} onClick={onClick}>
      <span>{children}</span>
    </button>
  );
};

export default ActionButton;
