import { MediaQuery, mq } from '@/ui/styles/mediaQueries';
import { css } from '@emotion/css';

export const header = (
  isLeaderboardPage: boolean,
  isWorkshopsPage: boolean
) =>
  css({
    transition: 'all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1)',
    position: 'sticky',
    width: '100%',
    zIndex: 10,
    top: 0,
    display: 'flex',
    background: isWorkshopsPage
      ? '#ffffff'
      : isLeaderboardPage
      ? 'var(--gray-800, #27272a)'
      : '#f7f6ee',
  });

export const container = css({
  position: 'relative',
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: '1440px',
  [mq(MediaQuery.Mobile)]: {
    width: '100%',
    marginLeft: '0',
    marginRight: '0',
  },
});

export const navigation = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '1.5rem',
  height: '140px',
  position: 'relative',
  zIndex: '7',
  [mq(MediaQuery.Mobile)]: {
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    height: '80px',
  },
});

export const navigationWorkshops = css({
  background: '#ffffff',
});

export const navigationRanking = css({
  background: 'var(--gray-800, #27272a)',
  color: '#ffffff',
});

export const logoWrapper = css({
  marginRight: '2rem',
});

export const navLinksWrapper = (menuToggled: boolean) =>
  css({
    display: 'flex',
    alignItems: 'center',
    gap: '2rem',
    [mq(MediaQuery.Mobile)]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100vw',
      zIndex: '8',
      display: menuToggled ? 'flex' : 'none',
      position: 'absolute',
      top: '75px',
      left: '0',
      background: '#f7f6ee',
      gap: '1px',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      paddingBottom: '10px',
      boxShadow: '10px 4px 5px 0px rgba(0, 0, 0, 0.25)',
    },
  });

export const hamburgerMenu = css({
  display: 'none',
  [mq(MediaQuery.Mobile)]: {
    display: 'block',
    '& button': {
      fontSize: '13px',
    },
    '& button:first-of-type': {
      border: '1px solid var(--Gradient-1, #FDD518)',
    },
  },
});

export const mobileButtonsWrapper = css({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
});
export const navLink = (isLeaderboardPage: boolean) =>
  css({
    position: 'relative',
    display: 'inline-block',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '132%',
    textDecoration: 'none',
    color: isLeaderboardPage ? '#ffffff' : 'var(--gray-800, #27272a)',
    overflow: 'hidden',
    transition: '.3s',
    '&:hover::before': {
      opacity: 1,
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      paddingBottom: '8px',
      zIndex: -1,
      opacity: 0,
      transition: 'opacity 0.5s ease',
    },
    '& span': {
      display: 'inline-block',
      transition: 'transform 0.3s ease',
      paddingBottom: '10px',
      borderBottom: '1px solid transparent',
    },
    '& span:hover': {
      background:
        'linear-gradient(97deg, #C32900 -5.49%, #FB5500 68.62%)',
      position: 'relative',
      zIndex: 1,
      backgroundClip: 'text',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      borderBottom: '1px solid #FD9B05',
      transition: '.3s',
    },
    '@media (max-width: 768px)': {
      width: '100%',
      padding: '20px 0',
      color: 'var(--gray-800, #27272a)',
    },
  });
