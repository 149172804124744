import { addDays, addHours, format, isAfter } from 'date-fns';
import { enGB, pl } from 'date-fns/locale';

export const formatDateWithAddingHours = (
  date: string | Date,
  hour: number,
  lang?: string
) => {
  if (!date) {
    return null;
  }

  const dateObject = parseDate(date);

  return format(
    addHours(dateObject, hour),
    `HH:mm, cccc, do ${(lang === 'pl' && 'MMMM') || 'MMM'}`,
    {
      locale: (lang === 'pl' && pl) || enGB,
    }
  );
};

export const formatHackathonDuration = (
  startDate: string | Date,
  endDate: string | Date,
  lang?: string
) => {
  if (!startDate || !endDate) {
    return null;
  }

  const startDateObject = parseDate(startDate);
  const endDateObject = parseDate(endDate);

  const start = format(startDateObject, 'cccc', {
    locale: (lang === 'pl' && pl) || enGB,
  });
  const end = format(endDateObject, 'cccc', {
    locale: (lang === 'pl' && pl) || enGB,
  });

  return `${capitalizeFirstLetter(start)} - ${capitalizeFirstLetter(
    end
  )}`;
};

export const formatEndDate = (date: string | Date, lang?: string) => {
  if (!date) {
    return null;
  }

  const dateObject = parseDate(date);

  return format(
    addHours(addDays(dateObject, 1), 18),
    `HH:mm, cccc, do ${(lang === 'pl' && 'MMMM') || 'MMM'}`,
    { locale: (lang === 'pl' && pl) || enGB }
  );
};

export const parseDate = (date: string | Date) =>
  typeof date === 'string' ? new Date(parseInt(date)) : date;

export const isCurrDatePassedDate = (date: string) =>
  isAfter(new Date(), parseDate(date));

const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const formatDateRangeFromTimestamps = (
  startAt: string | number,
  endAt: string | number,
  lang?: string
) => {
  const startDateObject = parseDate(startAt.toString());
  const endDateObject = parseDate(endAt.toString());

  const locale = lang === 'pl' ? pl : enGB;

  const startDay = format(startDateObject, 'dd', {
    locale,
  });
  const endDay = format(endDateObject, 'dd', {
    locale,
  });

  const startMonth = format(startDateObject, 'MMMM', {
    locale,
  });

  const startYear = format(startDateObject, 'yyyy', {
    locale,
  });

  return `${startDay} – ${endDay} ${capitalizeFirstLetter(
    startMonth
  )} ${startYear}`;
};

export const formatTimestampToDate = (
  timestamp: string | number | Date
) => {
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear());
  return `${day}.${month}.${year}`;
};
